import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";
import SimpleReactValidator from 'simple-react-validator';


const Contact = () => {


    const [forms, setForms] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [showForm, setShowForm] = useState(true);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
    };

    const submitHandler = async (e) => {
        e.preventDefault();

        if (!recaptchaValue) {
            // If reCAPTCHA is not verified, you can handle it accordingly
            console.error('Please verify reCAPTCHA');
            return;
        }

        if (validator.allValid()) {
            try {
                const templateParams = {
                    from_name: forms.name,
                    from_email: forms.email,
                    to_name: 'Abdul Cholik Studio', // Replace with your recipient's name
                    subject: forms.subject,
                    message: forms.message
                };

                await emailjs.send(
                    'service_l05mh24',
                    'template_7o5wtju',
                    templateParams,
                    '3BajXxRbVJwGd5942'
                );

                // Email sent successfully, do something (e.g., show success message)
                console.log('Email sent!');
                setShowForm(false);
                setShowSuccessMessage(true);
                
                // Clear form inputs after successful submission
                setForms({
                    name: '',
                    email: '',
                    subject: '',
                    phone: '',
                    message: ''
                });
            } catch (error) {
                // Handle error while sending email
                console.error('Email sending failed:', error);
            }
        } else {
            validator.showMessages();
        }
    };



    return (
        <div>
            {showForm && (
        <form onSubmit={(e) => submitHandler(e)} className="contact-validation-active" style={{ marginTop: '20px'}}>
            <div className="row">
                <div className="col col-lg-6 col-md-6 col-12">
                    <div className="form-field">
                        <input
                            className="form-control"
                            value={forms.name}
                            type="text"
                            name="name"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your Name" />
                    </div>
                    {validator.message('name', forms.name, 'required|alpha_space')}
                </div>
                <div className="col col-lg-6 col-md-6 col-12">
                    <div className="form-field">
                        <input
                            className="form-control"
                            value={forms.email}
                            type="email"
                            name="email"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your Email" />
                        {validator.message('email', forms.email, 'required|email')}
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <select className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            value={forms.subject}
                            type="text"
                            name="subject">
                            <option>Choose a Service</option>
                            <option value="web">Web Design</option>
                            <option value="ecommerce">E-Commerce</option>
                            <option value="support">WordPress Support</option>
                        </select>
                        {validator.message('subject', forms.subject, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col fullwidth col-lg-12">
                    <textarea
                        className="form-control"
                        onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                        value={forms.message}
                        type="text"
                        name="message"
                        placeholder="Message">
                    </textarea>
                    {validator.message('message', forms.message, 'required')}
                </div>
            </div>
            <div className="submit-area">
            <ReCAPTCHA
                            sitekey="6LeJQ8oqAAAAAFVd1yjFAYzwz1LcMaZ6QYw9ntxN"
                            onChange={handleRecaptchaChange}
                            theme='dark'
                        />
                <button type="submit" className="theme-btn-s2" style={{ marginTop: '30px', borderRadius: '0px'}}> Submit Now</button>
            </div>
        </form>
        )}
        {showSuccessMessage && (
                <div>
                    <p style={{ textAlign: 'center', marginTop:'15px'}}>Email sent successfully!</p>
                    {/* You can customize the success message as needed */}
                </div>
            )}
        </div>
    )
}

export default Contact;