import React from "react";
import { NavLink } from 'react-router-dom'
import himg1 from '../../images/slider/hero.jpeg'
import HeroTitle from '../../components/hero/herotitle'
import ImageSlider from '../../components/hero/imageslider'
import { Link } from 'react-scroll'



const Hero =() => {
    return (
        <section className="tp-hero-section-1">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-7 col-lg-7">
                        <div className="tp-hero-section-text">
                            <HeroTitle />
                            <div className="tp-hero-sub">
                                <p>Abdul Cholik Studio</p>
                            </div>
                            <div className="btns">
                                <Link activeClass="active" to="contact" spy={true} smooth={true} duration={500} offset={-95} className="theme-btn">Contact Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="right-vec">
                <div className="right-img">
                    <ImageSlider />
                </div>
            </div>
            <div className="social-link">
                <ul>
                    <li><a href="https://x.com/abcstudioid" target="_blank">X/Twitter</a></li>
                    <li><a href="https://wa.me/6285175284785" target="_blank">WhatsApp</a></li>
                    <li><a href="mailto:hello@abc-studio.id" target="_blank">Email</a></li>
                </ul>
            </div>
            <div className="visible-text">
                <h1>Abdul Cholik Studio</h1>
            </div>
        </section>
    )
}

export default Hero;