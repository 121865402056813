import { useState, useEffect } from "react";

const HeroTitle = () => {
  const text = "WordPress &\nE-Commerce Specialist";
  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);
  const typingSpeed = 100; // Speed of typing
  const delayBeforeRestart = 7000; // 10 seconds delay before restarting

  useEffect(() => {
    if (index < text.length) {
      const timeout = setTimeout(() => {
        setDisplayedText((prev) => prev + text[index]);
        setIndex((prev) => prev + 1);
      }, typingSpeed);
      return () => clearTimeout(timeout);
    } else {
      const restartTimeout = setTimeout(() => {
        setDisplayedText("");
        setIndex(0);
      }, delayBeforeRestart);
      return () => clearTimeout(restartTimeout);
    }
  }, [index, text]);

  return (
    <div className="tp-hero-title">
      <h2>{displayedText.split('\n').map((line, i) => (
        <span key={i}>
          {line}
          <br />
        </span>
      ))}</h2>
    </div>
  );
};

export default HeroTitle;
