import React from "react"
import { useState, useEffect } from 'react'
import { motion } from "framer-motion"
import himg1 from '../../images/slider/hero.jpeg'
import himg2 from '../../images/slider/hero2.jpeg'

const images = [
    { src: himg1, alt: "WordPress & E-Commerce Specialist" },
  ];

const ImageSlider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 5000);
      return () => clearInterval(interval);
    }, []);
  
    return (
      <div className="relative w-full h-auto">
        <motion.img
          key={currentIndex}
          src={images[currentIndex].src}
          alt={images[currentIndex].alt}
          className="w-full h-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.9 }}
        />
      </div>
    );
  };
  
  export default ImageSlider;